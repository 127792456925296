var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "main-box" },
        _vm._l(_vm.icons, function (icon) {
          return _c("div", { staticClass: "project-box" }, [
            _c(
              "div",
              {
                style: `background-image: url(/svg/${icon.icon}.svg);`,
                on: {
                  click: function ($event) {
                    return _vm.showDetail(icon)
                  },
                },
              },
              [
                _c("div", { staticClass: "node-name" }, [
                  _vm._v(_vm._s(icon.nodeName)),
                ]),
              ]
            ),
          ])
        }),
        0
      ),
      _vm.dialogVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.title,
                "destroy-on-close": "",
                "close-on-press-escape": false,
                "close-on-click-modal": true,
                visible: _vm.dialogVisible,
                width: "80%",
              },
              on: {
                close: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            _vm._l(_vm.children, function (obj) {
              return _c(
                "div",
                {
                  staticClass: "child-box",
                  on: {
                    click: function ($event) {
                      return _vm.onView(obj)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(obj.nodeName) + " ")]
              )
            }),
            0
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }