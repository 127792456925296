<template>
  <div>
    <div class="main-box">
      <div v-for="icon in icons" class="project-box">
        <div :style="`background-image: url(/svg/${icon.icon}.svg);`" @click="showDetail(icon)">
          <div class="node-name">{{ icon.nodeName }}</div>
        </div>
      </div>
    </div>
    <el-dialog :title="title" destroy-on-close :close-on-press-escape="false"
        :close-on-click-modal="true" v-if="dialogVisible" :visible="dialogVisible"
        @close="dialogVisible = false;" width="80%">
        <div v-for="obj in children" class="child-box" @click="onView(obj)">
          {{ obj.nodeName }}
        </div>
      </el-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters([ 'menu' ]),
  },
  data () {
    return {
      dialogVisible: false,
      title: "",
      icons: [],
      children: []
    }
  },
  created () {
    const icons = [];
    for (const menu of this.menu) {
      if ( menu.icon === "home" ) continue;
      if ( menu.icon === "admin" ) continue;

      icons.push(menu);
    }

    this.icons = icons;
  },
  methods: {
    showDetail (icon) {
      this.dialogVisible = true;
      this.title = icon.nodeName;
      this.children = icon.children || [ icon ];
    },
    onView (obj) {
      this.$router.push({
        path: obj.icon
      });
    }
  }
}
</script>
<style lang="scss" scoped>
.main-box {
  width: 100%;
  text-align: center;
  margin-top: 10vh;

  .project-box {
    width: 31vw;
    padding: 25px;
    display: inline-block;

    >div {
      color: #FFFFFF;
      border:2px solid #FFFFFF;
      border-radius: 20px;
      height: 33vh;
      cursor: pointer;
      background-size: 20vh;
      background-repeat: no-repeat;
      background-position-x: 30px;
      background-position-y: 30px;
      position: relative;

      .node-name {
        position: absolute;
        top:  23vh;
        left: 30vh;
        font-size: 36px;
      }
    }

    >div:hover {
      animation: donghua 1s forwards;
    }
  }
}

.child-box {
  width: 200px;
  text-align: center;
  display: inline-block;
  padding: 30px;
  margin-right: 30px;
  border-radius: 20px;
  border:2px solid rgba($color: #163b95, $alpha: 0.7);
  background: #FFFFFF;
  cursor: pointer;
}

.child-box:hover {
  animation: donghua 1s forwards;
}

::v-deep .el-dialog {
  border:2px solid #FFFFFF;
  border-radius: 10px;
  background: rgba($color: #FFFFFF, $alpha: 0.6);
  backdrop-filter: blur(7px);
}

::v-deep .el-dialog__title {
  font-weight: bold;
}

@keyframes donghua {
  40% {
    color: rgb(250, 225, 180);
    background-color: rgba($color: #3a8ee6, $alpha: 0.4);
  }

  100% {
    color: #E6A23C;
    background-color: rgba($color: #3a8ee6, $alpha: 0.3);
  }
}

</style>